.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.hero-section {
  margin-bottom: 50px; /* Adjust space below the hero section */
  background-color: #fff; /* or any color that matches your design */
}

.wraparound-section {
  padding-top: 50px; /* Adds space at the top of the wraparound section */
  background-color: #f8f9fa; /* slightly different background to distinguish */
  border-top: 2px solid #e0e0e0; /* optional divider */
}
